<template>
  <dash-page :title="$t('Help')"
              icon="mdi-help-circle-outline">

    <template #default>

      <v-sheet
          v-for="(item,i) in items"
          :style="`border : 1px solid ${wsBACKGROUND}`"
               class="wsRoundedLight mt-3 px-6 py-3"
               :key="i"  >

        <div class="d-flex justify-space-between align-center">
          <h4 class="font-weight-medium">{{ item.name }}</h4>
          <v-btn @click="expand(item)" icon ><v-icon>mdi-chevron-{{ item.expand ? 'up' : 'down' }}</v-icon></v-btn>
        </div>

        <v-expand-transition>
          <div v-if="item.expand" >
            <v-divider class="mt-1" />
            <div class="py-6"   v-html="item.content" />
          </div>

        </v-expand-transition>


      </v-sheet>

    </template>

    <template #side>
      <v-btn @click="openDialog('setup')" outlined block class="noCaps mt-3" dark > {{ $t('OrderSetup') }}</v-btn>
      <v-btn @click="openDialog('consult')" outlined block class="noCaps mt-3" dark > {{ $t('OrderConsultation') }}</v-btn>
      <v-btn @click="openDialog('proposition')" outlined block class="noCaps mt-3" dark > {{ $t('LeaveProposition') }}</v-btn>
      <v-btn @click="openDialog('bug')" outlined block class="noCaps mt-3" dark > {{ $t('ReportBug') }}</v-btn>
    </template>

    <template #dialog>

      <wsDialog v-model="displayDialog"
                :width="dialogWidth"
                :title="$t('Help')"
                @save="addEvent"
                :save-text="$t('Confirm')">

        <h5 class="mb-6 font-weight-medium  d-flex align-center"><v-icon  large class="mr-3" :color="wsACCENT">mdi-information</v-icon>{{  dialogText  }}</h5>

        <div v-if="entityData.type === 'video_call'"  class="mb-6">
          <ws-date-picker  v-model="entityData.date_scheduled" :label="$t('Date')" />
        </div>

        <v-textarea height="300" v-model="entityData.text" outlined dense :label="$t('Comment')" />


      </wsDialog>
  

    </template>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "BusinessHelp",
  data() {
    return {
      dialogWidth : 600,
      items : [],
      displayDialog : false,
      displayConsultation : false,
      displayProposition : false,
      displayBug : false,
      entityData : {},
      displayType : 'setup',
    }
  },
  computed : {
    dialogText() {

      switch(this.displayType) {

        case 'setup'       : return this.$t('OrderSetupText'       )
        case 'consult'     : return this.$t('OrderConsultationText')
        case 'proposition' : return this.$t('BusinessUpdatePropositionText' )
        case 'bug'         : return this.$t('ReportBugText'        )
        default : return ''

      }


    }
  },
  methods : {
    ...mapActions('faq', [
          'GET_FAQ_ITEMS',
          'GET_FAQ_ITEM'
        ]
    ),
    ...mapActions('adminCrmSystem', [
          'ADMIN_CRM_CLIENT_ADD_BUSINESS_EVENT',
        ]
    ),

    async addEvent() {
      this.entityData.text = this.entityData.text.replace(/\n/gi, '<br>');
      if (!await this.ADMIN_CRM_CLIENT_ADD_BUSINESS_EVENT(this.entityData)) {
        return this.notify(this.$t('Error'))
      }
      this.displayDialog = false
      this.notify('Обʼєкт збережено')
    },

    openDialog(type) {
      this.dialogWidth = 600
      this.entityData = {};
      this.displayType = type
      this.displayDialog = true
      switch(type) {
        case 'setup' :
          this.dialogWidth = 800
          this.entityData = {
            type : 'task',
            name : this.$t('BusinessSetupOrder')
          }
          break;
        case 'consult' :
          this.entityData = {
            type : 'video_call',
            name : this.$t('BusinessVideoConsultOrder')
          }
          break;
        case 'bug' :
          this.dialogWidth = 800
          this.entityData = {
            type : 'bug',
            name : this.$t('BusinessBugReport')
          }
          break;
        case 'proposition' :
          this.dialogWidth = 800
          this.entityData = {
            type : 'task',
            name : this.$t('BusinessUpdateProposition')
          }
          break;
      }
    },
    openConsultation() {
      this.displayConsultation = true
    },
    openProposition() {
      this.displayProposition = true
    },
    openBug() {
      this.displayBug = true
    },

    saveSetup() {
      this.displaySetup = false
    },
    saveConsultation() {
      this.displayConsultation = false
    },
    saveProposition() {
      this.displayProposition = false
    },
    saveBug() {
      this.displayBug = false
    },

    async expand(item) {

      if ( !item.expand ) {
        let result = await this.GET_FAQ_ITEM(item.uuid)
        if ( !result || result === true ) { return }
        item.content = result.content
        item.expand = true
      } else {
        item.expand = false
      }


    }
  },
  async mounted(){

    let result = await this.GET_FAQ_ITEMS(this.is_westudy ? 'westudy' : 'fine')
    if ( !result || result === true ) {
      return
    }
    this.items = result
  }
}
</script>

<style scoped>

</style>